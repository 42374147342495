import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TextLink from "../../components/TextLink"
import Album from '../../components/Album'
import bioPhoto from '../../images/Darling-Coils.jpg'
import { darlingCoilsAlbums } from '../../data/albums'
import './style.css'

const DarlingCoils = () => (
  <Layout>
    <SEO title="Darling Coils" />
    <div className="artist-page">
      <div className="artist-page__bio">
        <div>
          <h1>Darling Coils</h1>
          <h2>Passaic, NJ</h2>
        </div>
        <p>
          Producer, singer, songwriter from NC. Formerly known as Def Hazy. Former member of Lucid Colony, Diluted Giants, and Happy White People with Money. Also, current member of Counterfit and The Kooksters.
        </p>
        <div className="artist-page__bio-links">
          <TextLink href='https://open.spotify.com/artist/2zjXg5cNcifEBB32PQ4kFH?si=0nRdpAkITyiOA34JIT6JXg'>Spotify</TextLink>
          <TextLink href='https://soundcloud.com/matthew-paul-reinheimer'>Soundcloud</TextLink>
          <TextLink href='https://darlingcoils.bandcamp.com/'>Bandcamp</TextLink>
          <TextLink href='https://www.instagram.com/darling_coils/'>Instagram</TextLink>
          <TextLink href='https://twitter.com/MatthewReinheim'>Twitter</TextLink>
        </div>
      </div>
      <div className="artist-page__photo">
        <img src={bioPhoto} alt="Darling Coils" />
      </div>
      <div className="artist-page__albums">
        <h3>Albums</h3>
        <div className="artist-page__albums-list">
          {
            darlingCoilsAlbums.map((album, index) => <Album key={index} album={album} />)
          }
        </div>
      </div>
    </div>
  </Layout>
)

export default DarlingCoils
